/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-shadow */
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
  ReactNode,
} from 'react';
import classNames from 'classnames';
import { usePathPrefix } from '../../../../constants/hooks';
import type { screens, platforms, Platform } from './types';
import root from '../../styles.module.scss';
import styles from './styles.module.scss';
import { CaseDevice, DeviceSettings } from '../CaseDevice';
import { CaseDesktop } from '../CaseDesktop';
import { AndroidIcon } from '../../../icons/AndroidIcon';
import { AppleIcon } from '../../../icons/AppleIcon';
import { t } from '../../../../i18n';
import { getFileNameFromPath } from '../../../../utils/getFileNameFromPath';
import { IconComponent } from '../../../common/Icon';
import AppStore from '../../../../images/LinkStore/AppStore.png';
import GooglePlay from '../../../../images/LinkStore/GooglePlay.png';
import { WebIcon } from '../../../icons/WebIcon';
import { CasePresentation } from '../CasePresentation';

export type buttonProps = {
  value: number;
  onChange: React.Dispatch<React.SetStateAction<number>>;
  current: number;
  variant: [string, string, string?];
};

interface IProps {
  screens: screens;
  platforms: platforms;
  linkStore?: {
    iOS?: string;
    Android?: string;
  };
  resultParagraphs: string[];
  renderButton: (buttonProps: buttonProps) => ReactNode;
  colors: {
    primary: string;
    secondary: string;
  };
  presentation?: boolean;
}

const deviceSettings: Record<string, DeviceSettings> = {
  'iphone-x': {
    width: 375,
    height: 740,
    screenWidth: 320,
    screenHeight: 693,
    x: 27,
    y: 25,
    zIndex: 0,
  },
  android: {
    width: 375,
    height: 740,
    screenWidth: 320,
    screenHeight: 693,
    x: 27,
    y: 25,
    zIndex: 1,
  },
  'androidTablet-portrait': {
    width: 471,
    height: 700,
    screenWidth: 385,
    screenHeight: 525,
    x: 44,
    y: 86,
    zIndex: 1,
  },
};

const icons = {
  Android: AndroidIcon,
  iOS: AppleIcon,
  Desktop: WebIcon,
};

const CaseResult: FC<IProps> = ({
  screens,
  platforms,
  linkStore,
  resultParagraphs,
  renderButton,
  colors,
  presentation,
}) => {
  const prefix = usePathPrefix();

  const [variant, setVariant] = useState(0);
  const [platform, setPlatform] = useState<Platform>(
    Object.keys(screens)[0] as any
  );

  const variants = useMemo<[string, string, string?][]>(
    () => screens[platform] || [],
    [prefix, platform, screens]
  );

  const images = useMemo(() => variants.map((v) => v[1]), [variants]);
  const availablePlatforms = useMemo(
    () => Object.keys(screens).map((p: unknown) => platforms[p as Platform]),
    []
  );

  const switchToNextVariant = useCallback(
    () => setVariant(variant < variants.length - 1 ? variant + 1 : 0),
    [variant, setVariant, variants]
  );

  const switchToPreviousVariant = useCallback(
    () => setVariant(variant > 0 ? variant - 1 : variants.length - 1),
    [variant, setVariant, variants]
  );

  const switchPlatform = useCallback(
    () => setPlatform((prev) => (+prev ? 0 : 1)),
    []
  );

  useEffect(() => setVariant(0), [platform]);

  const activeDevice = platforms[platform][2];
  const activeDeviceName = getFileNameFromPath(activeDevice);
  const isDesktop = platforms[platform][0] === 'Desktop';

  return (
    <div
      className={classNames(
        root.screen,
        isDesktop ? styles.resultDesktop : styles.result
      )}
    >
      <div className={isDesktop ? styles.textResult : styles.text}>
        <h2>{t('case.results')}</h2>

        {resultParagraphs.map((paragraph, i) => (
          // eslint-disable-next-line react/no-danger
          <p key={paragraph} dangerouslySetInnerHTML={{ __html: paragraph }} />
        ))}

        {!isDesktop && (
          <div className={styles.arrows}>
            {availablePlatforms.length > 1 && (
              <div
                className={classNames(styles.arrows__arrow)}
                onClick={switchPlatform}
              >
                <svg
                  width="12"
                  height="20"
                  viewBox="0 0 12 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.98811 0L12 2.01429L4.02378 10L12 17.9857L9.98811 20L0 10L9.98811 0Z"
                    fill={colors.primary}
                  />
                </svg>
              </div>
            )}

            <div className={styles.platforms}>
              <div>
                <IconComponent
                  name={availablePlatforms[platform][0]}
                  iconProps={{ color: colors.primary }}
                  icons={icons}
                />
                <span
                  style={{
                    color: colors.primary,
                  }}
                >
                  {platforms[platform][0]}
                </span>
              </div>
            </div>

            {availablePlatforms.length > 1 && (
              <div className={styles.arrows__arrow} onClick={switchPlatform}>
                <svg
                  width="12"
                  height="20"
                  viewBox="0 0 12 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.01189 20L-1.76094e-07 17.9857L7.97622 10L-1.57236e-06 2.01429L2.01189 8.73188e-07L12 10L2.01189 20Z"
                    fill={colors.primary}
                  />
                </svg>
              </div>
            )}
          </div>
        )}

        {!isDesktop && (
          <div className={classNames(styles.platforms, styles.hidden)}>
            {availablePlatforms.map((p, i) => (
              <div
                key={p[0]}
                onClick={() => setPlatform(Object.keys(screens)[i] as any)}
              >
                <IconComponent
                  name={p[0]}
                  icons={icons}
                  iconProps={{
                    color: +platform === i ? colors.primary : '',
                  }}
                />
                <span
                  style={{
                    color: +platform === i ? colors.primary : '',
                  }}
                >
                  {p[0]}
                </span>
              </div>
            ))}
          </div>
        )}

        {isDesktop && (
          <>
            <div className={styles.arrows}>
              <div
                className={classNames(styles.arrows__arrow)}
                onClick={switchToPreviousVariant}
              >
                <svg
                  width="12"
                  height="20"
                  viewBox="0 0 12 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.98811 0L12 2.01429L4.02378 10L12 17.9857L9.98811 20L0 10L9.98811 0Z"
                    fill={colors.primary}
                  />
                </svg>
              </div>

              <div
                className={styles.arrows__value}
                style={{ color: colors.primary }}
              >
                {(variants[variant] && variants[variant][0]) || ''}
              </div>

              <div
                className={styles.arrows__arrow}
                onClick={switchToNextVariant}
              >
                <svg
                  width="12"
                  height="20"
                  viewBox="0 0 12 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.01189 20L-1.76094e-07 17.9857L7.97622 10L-1.57236e-06 2.01429L2.01189 8.73188e-07L12 10L2.01189 20Z"
                    fill={colors.primary}
                  />
                </svg>
              </div>
            </div>
            <p className={styles.description}>{variants[variant][2]}</p>
          </>
        )}

        {isDesktop ? (
          <div className={styles.desktopButtonsScreens}>
            <div className={styles.desktopButtons}>
              {variants.map((v, i) =>
                renderButton({
                  value: i,
                  onChange: setVariant,
                  current: variant,
                  variant: v,
                })
              )}
            </div>
            <div className={styles.deviceDesktop}>
              <CaseDesktop images={images} active={variant} />
            </div>
          </div>
        ) : (
          <div className={styles.buttons}>
            {variants.map((v, i) =>
              renderButton({
                value: i,
                onChange: setVariant,
                current: variant,
                variant: v,
              })
            )}
          </div>
        )}

        {!isDesktop && (
          <>
            <div className={styles.arrows}>
              <div
                className={classNames(styles.arrows__arrow)}
                onClick={switchToPreviousVariant}
              >
                <svg
                  width="12"
                  height="20"
                  viewBox="0 0 12 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.98811 0L12 2.01429L4.02378 10L12 17.9857L9.98811 20L0 10L9.98811 0Z"
                    fill={colors.primary}
                  />
                </svg>
              </div>
              <div
                className={styles.arrows__value}
                style={{ color: colors.primary }}
              >
                {(variants[variant] && variants[variant][0]) || ''}
              </div>
              <div
                className={styles.arrows__arrow}
                onClick={switchToNextVariant}
              >
                <svg
                  width="12"
                  height="20"
                  viewBox="0 0 12 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.01189 20L-1.76094e-07 17.9857L7.97622 10L-1.57236e-06 2.01429L2.01189 8.73188e-07L12 10L2.01189 20Z"
                    fill={colors.primary}
                  />
                </svg>
              </div>
            </div>
            <p
              className={styles.description}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: variants[variant][2] || '' }}
            />
          </>
        )}

        <div className={styles.store_link_desktop}>
          {linkStore?.iOS ? (
            <a href={linkStore.iOS} target="_blank" rel="noreferrer">
              <img src={AppStore} alt="" />
            </a>
          ) : (
            <></>
          )}
          {linkStore?.Android ? (
            <a href={linkStore.Android} target="_blank" rel="noreferrer">
              <img src={GooglePlay} alt="" />
            </a>
          ) : (
            <></>
          )}
        </div>

        {presentation && <CasePresentation />}
      </div>

      {!isDesktop && (
        <div className={styles.device}>
          <CaseDevice
            images={images}
            activeDevice={platforms[platform][2]}
            active={variant}
            deviceSettings={deviceSettings[activeDeviceName ?? '']}
          />
          <div className={styles.store_link_mobile}>
            {linkStore?.iOS ? (
              <a href={linkStore.iOS} target="_blank" rel="noreferrer">
                <img src={AppStore} alt="" />
              </a>
            ) : (
              <></>
            )}
            {linkStore?.Android ? (
              <a href={linkStore.Android} target="_blank" rel="noreferrer">
                <img src={GooglePlay} alt="" />
              </a>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export { CaseResult };
