/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import classNames from 'classnames';
import { Modal } from '../../../../main/Modal';
import { t } from '../../../../../i18n';
import { CaseButton } from '../../CaseButton';
import styles from './styles.module.scss';
import root from '../../../styles.module.scss';
import {
  useCloseOnEscape,
  useResetCaptcha,
  useTc,
} from '../../../../../constants/hooks';
import { Captcha } from '../../../../inputs/Captcha';
import { sendPresentationRequest } from '../../../../../utils/api';
import { TextInputWithIcon } from '../../../../common/TextInputWithIcon';
import formInputs from './constant';
import { OopsPanel } from '../../../../common/OopsPanel';
import { Checkbox } from '../../../../inputs/Checkbox';
import { getFromUrl } from '../../../../../utils/getFromUrl';

interface IProps {
  onClose: () => void;
  onSuccess: () => void;
}

const STATES = {
  SUCCESS: 'success',
  LOADING: 'loading',
};

const PresentationModal: FC<IProps> = ({ onClose, onSuccess }) => {
  const [subscribe, setSubscribe] = useState(true);
  const [captcha, setCaptcha] = useState('');
  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const [state, setState] = useState('');
  const [inputsState, setInputsState] = useState<Record<string, string>>({});
  const { email } = inputsState;
  const [isSuccess, setIsSuccess] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const resetCaptcha = useResetCaptcha(setCaptcha);

  const path = getFromUrl();

  const validateFields = useCallback(() => {
    const errorsField = {
      email: !email || email.length <= 5,
      captcha: !captcha,
    };

    setErrors(errorsField);

    return !Object.values(errorsField).some((val) => val);
  }, [captcha, email, setErrors]);

  const tc = useTc();
  const fieldIsRequired = useMemo(
    () => tc('cases.delivery.contacts.required'),
    [tc]
  );

  const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const nameInput = e.target.name;
    const { value } = e.target;
    if (errors[nameInput])
      setErrors((prevState) => ({ ...prevState, [nameInput]: false }));

    setInputsState((prevState) => ({
      ...prevState,
      [nameInput]: value,
      from: `/${path}`,
    }));
  };

  const onSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      try {
        if (!validateFields()) return;

        setState(STATES.LOADING);

        await sendPresentationRequest({
          email,
          subscribe,
          captcha,
        });

        if ((window as any).grecaptcha) {
          (window as any).grecaptcha.reset();
        }

        onSuccess();
        onClose();
        setState(STATES.SUCCESS);
        setIsSuccess(true);
      } catch (e) {
        setErrorMessage(e.message);
        setState('');
      } finally {
        resetCaptcha();
      }
    },
    [validateFields, setState, onSuccess, onClose, errorMessage]
  );

  useEffect(() => {
    if (captcha !== '') {
      setErrors((prevState) => ({ ...prevState, captcha: false }));
    }
  }, [captcha]);

  useCloseOnEscape(onClose);

  return (
    <>
      <Modal onClose={onClose}>
        <OopsPanel isOpen={Boolean(errorMessage)} errorMessage={errorMessage} />
        <div
          className={classNames(styles.modal__content, {
            [styles.loading]: state === STATES.LOADING,
            [styles.success]: state === STATES.SUCCESS,
          })}
        >
          <div className={styles.modal__close} onClick={onClose}>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.439362 0.439339C-0.146454 1.02512 -0.146454 1.97489 0.439362 2.56068L6.87869 9.00001L0.468781 15.4099C-0.117035 15.9957 -0.117035 16.9454 0.468781 17.5312C1.0546 18.117 2.0043 18.117 2.59012 17.5312L9.00003 11.1213L15.2886 17.4099C15.8744 17.9957 16.8241 17.9957 17.4099 17.4099C17.9958 16.8241 17.9958 15.8744 17.4099 15.2886L11.1214 9.00001L17.4394 2.68198C18.0252 2.0962 18.0252 1.14646 17.4394 0.560677C16.8535 -0.0251083 15.9038 -0.0251083 15.318 0.560677L9.00003 6.87867L2.5607 0.439339C1.97488 -0.146446 1.02518 -0.146446 0.439362 0.439339Z"
                fill="#55439D"
              />
            </svg>
          </div>

          <div className={root.center}>
            <div className={styles.title}>
              {t('cases.presentation.modal_title')}
            </div>
          </div>

          <form
            className={styles.modal__inputs}
            id="presentation-modal-form"
            onSubmit={onSubmit}
          >
            {formInputs.map(({ nameInput, label, icon }) => (
              <TextInputWithIcon
                name={nameInput}
                value={inputsState[nameInput] ?? ''}
                handler={onChange}
                label={label}
                icon={`${icon}`}
                hasError={errors[nameInput]}
                key={nameInput}
                isSuccess={isSuccess}
                id={`contact-${nameInput}`}
              />
            ))}
            <div className={styles.form_checkbox}>
              <Checkbox checked={subscribe} handler={setSubscribe} />
              {t('order.subscribe')}
            </div>
            <div className={styles.form_footer}>
              <Captcha
                handler={setCaptcha}
                error={(errors.captcha && fieldIsRequired) || ''}
              />
            </div>
            <input type="hidden" name="from" id="from" value={path} />

            <p className={styles.personel_area}>
              Нажимая кнопку, вы даете согласие на обработку своих &nbsp;
              <a href="/case/restocare/terms.pdf">Персональных данных</a>
            </p>
            <CaseButton>{t('cases.delivery.contacts.send')}</CaseButton>

            <div id="html_element" />
          </form>
        </div>
      </Modal>
    </>
  );
};

export { PresentationModal };
